


















import Vue from 'vue';

export default Vue.extend({
  props: {
    title: String,
    value: Boolean,
    hookDone: Function,
    hookReset: Function,
  },

  data() {
    return {
      loading: false,
    };
  },

  watch: {
    value() {
      this.hookReset();
    },
  },

  methods: {
    cancelClickHandler() {
      this.loading = false;
      this.$emit('input', false);
      this.$emit('cancel');
    },

    doneClickHandler() {
      this.loading = true;
      this.hookDone().finally(() => {
        this.loading = false;
        this.$emit('input', false);
        this.$emit('done');
      });
    },
  },
});
