


























import Vue from 'vue';

export default Vue.extend({
  props: {
    tickerId: String,
    orderQuantity: Number,
    unitPrice: Number,
  },
});
